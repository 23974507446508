.background {
  position: relative;
  min-height: 100vh;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
  width: 100%;
  z-index: 1;
}

.backgroundNavOpen {
  transform: translateX(-300px);
}
