@import './styleguide.scss';

:root {
  --spaceXS: 15px;
  --spaceSmall: 30px;
  --spaceMedium: 60px;
  --spaceLarge: 90px;
  --spaceXL: 120px;
  --max-width: 1200px;
  --font-primary: 'Nunito Sans', sans-serif;
  --brand-dark: #072a3e;
  --brand-primary: #072a3e;
  --brand-light: #276d93;
  --text-dark: #072a3e;
  --black: #000000;
  --white: #ffffff;
  --teal: #739eb1;
  --icon-basket: url('../public/icon-basket.png');
  --icon-photo-hero-background: url('../public/waves-background.png');
  --icon-photo-hero-background-2x: url('../public/waves-background@2x.png');
  --icon-photo-hero-background-3x: url('../public/waves-background@3x.png');
  --link-color: #276d93;
}

.blue-gradient-background {
  background-image: linear-gradient(to bottom, #dfecf0, #bfd6e0);
}
